<template>
  <div class="member-layout">

    <div class="input-title">비밀번호 변경<span class="color-1">*</span></div>
    <div class="input-title-desc">영문,숫자,특수문자 조합으로 8~20자 이내로 입력해주세요.</div>

    <input-field v-model="password1" placeholder="새 비밀번호" type="password" :errorMessage="password1Msg" maxLength="20"/>
    <input-field v-model="password2" placeholder="새 비밀번호 확인" type="password" class="mt-10" :errorMessage="password2Msg" maxLength="20"/>
    <div class="mt-15">
      <button class="btn btn-x-large color-1 btn-block" @click="save">비밀번호 변경</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "newPassword",
  data: function () {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      password1: '',
      password1Msg: null,
      password2: '',
      password2Msg: null,
    }
  },
  watch: {
    password1() {
      this.password1Msg = null
    },
    password2() {
      this.password2Msg = null
    }
  },
  created() {
    if (!(this.email && this.token)) location.href = '/'
    this.checkToken()
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async checkToken() {
      const { result } = await this.$api.checkToken({
        email: this.email,
        token: this.token,
      })
      switch (result) {
        case 'success':
          break;
        case 'fail':
        case 'email-no-exist':
          location.href = '/'
          break;
        case 'token-expires':
          await this.$router.push('/invalidToken')
          break;
        default:
      }
    },
    validate() {
      if (!this.password1) {
        this.password1Msg = this.$msg('required.input')
        return false
      }
      if (!this.password1.patternCheck('password')) {
        this.password1Msg = this.$msg('password.check')
        return false
      }
      if (!this.password2 || this.password1 !== this.password2) {
        this.password2Msg = this.$msg('password.no-match')
        return false
      }
      return true
    },
    async save() {
      if (this.validate()) {
        const { result } = await this.$api.setPassword({
          email: this.email,
          password: this.password1,
          token: this.token,
        })
        if (result === 'success') this.alertPopup()
      }
    },
    alertPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('ok.update'),
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          location.href = '/login'
        },
      })
    },
  }
}
</script>
